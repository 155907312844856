import React from 'react';
import { useTable, usePagination } from 'react-table';

const Table = ({ data, columns }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );

  // Dynamically calculate the pagination buttons based on current page index
  const getPaginationButtons = () => {
    const currentPageIndex = pageIndex + 1;
    const totalPages = Math.ceil(data.length / 5); // Assuming 5 items per page
    const buttons = [];
    const numButtonsToShow = 10;

    // Determine the start and end page numbers
    let startPage = Math.max(1, currentPageIndex - Math.floor(numButtonsToShow / 2));
    let endPage = Math.min(totalPages, startPage + numButtonsToShow - 1);

    // Adjust the start and end page numbers if necessary
    if (endPage - startPage + 1 < numButtonsToShow) {
      startPage = Math.max(1, endPage - numButtonsToShow + 1);
    }

    // Generate pagination buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => gotoPage(i - 1)} // Subtract 1 to match zero-based indexing
          className={`bg-gray-300 text-black px-4 py-2 rounded-md mr-2 ${pageIndex + 1 === i ? 'bg-red-500 text-white' : ''}`}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  return (
    <>
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} key={column.id} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} key={cell.column.id} className="px-6 py-4 whitespace-nowrap">
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="pagination flex justify-center my-4">
        {/* First and previous page buttons */}
        <button key={"firstPageBtn"} onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="bg-gray-300 text-black px-4 py-2 rounded-md mr-2">Prva</button>
        <button key={"previousPageBtn"} onClick={() => previousPage()} disabled={!canPreviousPage} className="bg-gray-300 text-black px-4 py-2 rounded-md mr-2">Nazad</button>
        
        {/* Dynamically generated pagination buttons */}
        {getPaginationButtons()}

        {/* Next and last page buttons */}
        <button key={"nextPageBtn"} onClick={() => nextPage()} disabled={!canNextPage} className="bg-gray-300 text-black px-4 py-2 rounded-md mr-2">Dalje</button>
        <button key={"lastPageBtn"} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="bg-gray-300 text-black px-4 py-2 rounded-md mr-2">Zadnja</button>
      </div>
    </>
  );
};

export default Table;
