import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/admin/Dashboard';
import Login from './pages/admin/Login';
import QrRegistration from './pages/fanzone/QrRegistration';
import withAuthProtection from './withAuthProtection';
import Members from './pages/admin/Members';
import QrGenerator from './pages/admin/QrGenerator';
import QrResolver from './services/qrResolver';
import FanZone from './pages/fanzone/FanZone';
import MemberDetail from './pages/admin/MemberDetail';

const AppRoutes = () => {
  const ProtectedDashboard = withAuthProtection(Dashboard);
  const ProtectedMembers = withAuthProtection(Members);
  const ProtectedQrGenerator = withAuthProtection(QrGenerator);
  const ProtectedMemberDetail = withAuthProtection(MemberDetail);

  return (
    <Routes>
      <Route path="/dashboard" element={<ProtectedDashboard />} />
      <Route path="/members" element={<ProtectedMembers />} />
      <Route path="/qr-generator" element={<ProtectedQrGenerator />} />
      <Route path="/member/:qrID" element={<ProtectedMemberDetail />} />
      <Route path="/login" element={<Login />} />
      <Route path="/qr/:qrID" element={<QrResolver />} />
      <Route path="/qr/fanzone/:qrID" element={<FanZone />} />
      <Route path="/qr/registration/:qrID" element={<QrRegistration />} />
    </Routes>
  );
};

export default AppRoutes;
