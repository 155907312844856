import React from 'react';
import Navbar from '../../components/shared/Navbar';

const Dashboard = () => {
  return (
    <div className="flex h-screen bg-gray-100">
     <Navbar />
      <div className="flex-1 p-8">
        {/* Main Content Goes Here */}
        <h1 className="text-2xl mb-4">Welcome to Dashboard</h1>
        <p>Your main content goes here...</p>
      </div>
    </div>
  );
};

export default Dashboard;
