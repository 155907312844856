// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth  } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAWOdRfG9EpaISD7KBheEe0dJGFcsY_GuM",
  authDomain: "mnk-bubamara-da512.firebaseapp.com",
  projectId: "mnk-bubamara-da512",
  storageBucket: "mnk-bubamara-da512.appspot.com",
  messagingSenderId: "610339543072",
  appId: "1:610339543072:web:b5817af68577e7518f8445",
  measurementId: "G-K0C5MC3Y77"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app); // Initialize Firestore and export it

export { auth, firestore }; 
