// Import necessary libraries and components
import React, { useState } from 'react';
import { addDoc, collection, updateDoc, query, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaUser, FaMapMarkerAlt, FaCity, FaGlobe, FaPhone, FaEnvelope, FaCalendarAlt } from 'react-icons/fa';

const QrRegistration = () => {
  // Get qrID from URL parameters
  const { qrID } = useParams();

  // Options for the country select input
  const countryOptions = [
    { value: 'ba', label: 'Bosna i Hercegovina' },
    { value: 'hr', label: 'Hrvatska' },
    { value: 'rs', label: 'Srbija' },
    { value: 'si', label: 'Slovenija' },
    { value: 'at', label: 'Austrija' },
    { value: 'de', label: 'Njemačka' },
    { value: 'ch', label: 'Švicarska' },
    { value: 'be', label: 'Belgija' },
    { value: 'it', label: 'Italija' },
    { value: 'fr', label: 'Francuska' },
    { value: 'usa', label: 'Amerika' },
    // Add more country options as needed
  ];

  // State variables
  const [formData, setFormData] = useState({
    fullName: '',
    dob: null,
    address: '',
    city: '',
    country: '',
    phone: '',
    email: '',
    qrID: qrID, // Add qrID to the form data state
  });

  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [errors, setErrors] = useState({});

  // Function to handle form input changes
  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  // Function to validate form fields
  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullName) {
      newErrors.fullName = 'Ime i prezime je obavezno polje!';
    }
    // Add other validation rules for other fields if needed
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      // Add a new document to the "users" collection
      const userRef = await addDoc(collection(firestore, 'users'), formData);
      console.log('User registered successfully with ID: ', userRef.id);

      // Query all documents from the qr_codes collection
      const q = query(collection(firestore, 'qr_codes'));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (doc) => {
          const qrData = doc.data();

          if (qrData['qrID'].toString() === qrID) {
            console.log("here");
            const qrDocRef = doc.ref;
            await updateDoc(qrDocRef, { isActive: true });
            console.log(`isActive updated for qrID ${qrID}`);
          }
        });
      } else {
        console.error('No documents found in the qr_codes collection.');
      }

      setSuccess(true);
    } catch (error) {
      console.error('Error registering user:', error);
      setFailure(true);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-red-800 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-lg shadow-lg">
        <div className="text-center">
          <img className="mx-auto h-12 w-auto" src="https://mnkbubamara.ba/wp-content/uploads/2023/06/logo-1.png" alt="Logo" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Registruj svoj profil</h2>
        </div>
        {!success && !failure && (
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div className="flex items-center border-b border-gray-300 py-2">
                <FaUser className="h-5 w-5 text-gray-400 mr-3" />
                <input
                  id="fullName"
                  name="fullName"
                  type="text"
                  autoComplete="name"
                  required
                  className={`appearance-none rounded-none relative block w-full px-3 py-2 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm ${errors.fullName && 'border-red-500'}`}
                  placeholder="Ime i prezime"
                  value={formData.fullName}
                  onChange={(e) => handleChange('fullName', e.target.value)}
                />
              </div>
              {errors.fullName && <p className="text-red-600 text-sm ml-8">{errors.fullName}</p>}
              <div className="flex items-center border-b border-gray-300 py-2">
                <FaCalendarAlt className="h-5 w-5 text-gray-400 mr-3" />
                <DatePicker
                  id="dob"
                  name="dob"
                  selected={formData.dob}
                  onChange={(date) => handleChange('dob', date)}
                  dateFormat="dd.MM.yyyy"
                  className={`appearance-none rounded-none relative block w-full px-3 py-2 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm ${errors.dob && 'border-red-500'}`}
                  placeholderText="Datum rođenja"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                />
              </div>
              {errors.dob && <p className="text-red-600 text-sm ml-8">{errors.dob}</p>}
              <div className="flex items-center border-b border-gray-300 py-2">
                <FaMapMarkerAlt className="h-5 w-5 text-gray-400 mr-3" />
                <input
                  id="address"
                  name="address"
                  type="text"
                  autoComplete="address"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  placeholder="Adresa"
                  value={formData.address}
                  onChange={(e) => handleChange('address', e.target.value)}
                />
              </div>
              <div className="flex items-center border-b border-gray-300 py-2">
                <FaCity className="h-5 w-5 text-gray-400 mr-3" />
                <input
                  id="city"
                  name="city"
                  type="text"
                  autoComplete="address-level2"
                  required
                  className={`appearance-none rounded-none relative block w-full px-3 py-2 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm ${errors.city && 'border-red-500'}`}
                  placeholder="Grad"
                  value={formData.city}
                  onChange={(e) => handleChange('city', e.target.value)}
                />
              </div>
              {errors.city && <p className="text-red-600 text-sm ml-8">{errors.city}</p>}
              <div className="flex items-center border-b border-gray-300 py-2">
                <FaGlobe className="h-5 w-5 text-gray-400 mr-3" />
                <Select
                  id="country"
                  name="country"
                  options={countryOptions}
                  required
                  className={`w-full ${errors.country && 'border-red-500'}`}
                  value={formData.country}
                  onChange={(selectedOption) => handleChange('country', selectedOption)}
                  placeholder="Izaberite državu"
                />
              </div>
              {errors.country && <p className="text-red-600 text-sm ml-8">{errors.country}</p>}
              <div className="flex items-center border-b border-gray-300 py-2">
                <FaPhone className="h-5 w-5 text-gray-400 mr-3" />
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  autoComplete="tel"
                  required
                  className={`appearance-none rounded-none relative block w-full px-3 py-2 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm ${errors.phone && 'border-red-500'}`}
                  placeholder="Telefon"
                  value={formData.phone}
                  onChange={(e) => handleChange('phone', e.target.value)}
                />
              </div>
              {errors.phone && <p className="text-red-600 text-sm ml-8">{errors.phone}</p>}
              <div className="flex items-center border-b border-gray-300 py-2">
                <FaEnvelope className="h-5 w-5 text-gray-400 mr-3" />
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) => handleChange('email', e.target.value)}
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full bg-red-700 text-white py-2 rounded-lg hover:bg-red-600 transition duration-300 uppercase"
              >
                Registracija
              </button>
            </div>
          </form>
        )}
        {success && (
          <p className="text-center text-green-600">Uspješno ste kreirali vaš profil. Hvala što ste dio Bubamara porodice.</p>
        )}
        {failure && (
          <p className="text-center text-red-600">Neuspješan pokušaj kreiranja profila. Pokušajte ponovo.</p>
        )}
      </div>
    </div>
  );
};

export default QrRegistration;

