import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/shared/Navbar";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebase";
import QRCode from "qrcode.react"; // Import QRCode component
import {
  FaUser,
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaEnvelope,
  FaPhone,
  FaPencilAlt,
} from "react-icons/fa"; // Import Font Awesome icons for member data

const MemberDetail = () => {
  const { qrID } = useParams();
  const [member, setMember] = useState(null);

  useEffect(() => {
    const fetchMemberDetails = async () => {
      try {
        const q = query(
          collection(firestore, "users"),
          where("qrID", "==", qrID)
        ); // Filter by qrID
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          console.log("Document data:", querySnapshot.docs[0].data()); // Log the retrieved data
          const data = querySnapshot.docs[0].data(); // Access the data of the first document
          setMember(data);
        } else {
          console.log("No such member found!");
        }
      } catch (error) {
        console.error("Error fetching member details:", error);
      }
    };

    fetchMemberDetails();
  }, [qrID]); // Removed 'member' from the dependencies array

  // Render loading state while member data is being fetched
  if (!member) {
    return <div>Loading...</div>;
  }

  // Function to format the date of birth
  const formatDate = (dob) => {
    const date = new Date(dob.seconds * 1000); // Convert timestamp to milliseconds
    return date.toLocaleDateString("hr-HR"); // Format date as "dd.mm.yyyy"
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Navbar />
      <div className="flex-1 p-8">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl mb-4">Detalji člana</h1>
          <button className="flex items-center bg-red-700 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300">
            <FaPencilAlt className="h-5 w-5 mr-2" />
            Uredi
          </button>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md mb-4 flex justify-between">
          <div className="flex flex-col space-y-2">
            <div className="flex items-center border-gray-300 py-2">
              <FaUser className="h-5 w-5 text-gray-400 mr-3" />
              <span>{member.fullName}</span>
            </div>
            <div className="flex items-center border-gray-300 py-2">
              <FaMapMarkerAlt className="h-5 w-5 text-gray-400 mr-3" />
              <span>
                {member.address}, {member.city}, {member.country.label}
              </span>
            </div>
            <div className="flex items-center py-2">
              <FaCalendarAlt className="h-5 w-5 text-gray-400 mr-3" />
              <span>{formatDate(member.dob)}</span> {/* Format date of birth */}
            </div>
            <div className="flex items-center border-gray-300 py-2">
              <FaEnvelope className="h-5 w-5 text-gray-400 mr-3" />
              <span>{member.email}</span>
            </div>
            <div className="flex items-center border-gray-300 py-2">
              <FaPhone className="h-5 w-5 text-gray-400 mr-3" />
              <span>{member.phone}</span>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <QRCode value={qrID.toString()} size={150} />
            <span className="text-sm text-gray-600 mt-2">{qrID}</span>
          </div>
        </div>
        <div className="flex mb-2">
          <button className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded mr-2">
            Članarine
          </button>
          <button className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded mr-2">
            Sezonske ulaznice
          </button>
          <button className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded mr-2">
            Donacije
          </button>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md mb-4 flex justify-between"></div>
      </div>
    </div>
  );
};

export default MemberDetail;
