import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Sign in user with email and password
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Set authentication cookie
      document.cookie = `authToken=${user.uid};max-age=86400`; // Cookie expires in 1 day

      // Redirect user to dashboard after successful login
      window.location.href = '/dashboard';
    } catch (error) {
      // Handle login errors
      console.error('Error signing in:', error);
      setError('Neuspješan pokušaj prijave. Pokušajte ponovo!');
    }
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <img src="https://mnkbubamara.ba/wp-content/uploads/2023/06/logo-1.png" alt="Logo" className="mx-auto mb-8" style={{ width: '150px' }} />
        <form className="space-y-4" onSubmit={handleLogin}>
          <div>
            <label htmlFor="email" className="block text-black uppercase">Email</label>
            <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-black" />
          </div>
          <div>
            <label htmlFor="password" className="block text-black uppercase">Password</label>
            <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-black" />
          </div>
          <button type="submit" className="w-full bg-red-700 text-white py-2 rounded-lg hover:bg-red-600 transition duration-300 uppercase">Login</button>
        </form>
        {error && <p className="text-red-500">{error}</p>}
      </div>
    </div>
  );
};

export default Login;
