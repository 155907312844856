import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import Navbar from '../../components/shared/Navbar';
import Table from '../../components/shared/Table'; // Import the shared Table component
import Loader from '../../components/shared/Loader'; // Import the Loader component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon component
import { faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons'; // Import Font Awesome icons
import { useNavigate } from 'react-router-dom';

const Members = () => {
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedMember, setSelectedMember] = useState(null); // State to store the selected member for deletion
  const [loading, setLoading] = useState(true); // State to manage loading status
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersSnapshot = await getDocs(collection(firestore, 'users'));
        const userData = usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setUsers(userData);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  // Function to handle search input change
  const handleSearch = (value) => {
    setSearchValue(value);
  };

  // Function to handle opening member details
  const viewMemberDetails = useCallback((member) => {
    // Use navigate to navigate to the member details page
    navigate(`/member/${member.qrID}`); // Assuming the member details page route is '/member/:id'
  }, [navigate]);

  // Function to handle confirming member deletion
  const confirmDeleteMember = useCallback((member) => {
    setSelectedMember(member);
    // Implement logic to show confirmation popup
    // You can use a modal or a custom popup component
  }, []);

  // Function to delete the selected member
  const deleteMember = async () => {
    try {
      if (selectedMember) {
        await deleteDoc(doc(firestore, 'users', selectedMember.id));
        console.log('Member deleted successfully.');
        // Fetch updated user data
        const usersSnapshot = await getDocs(collection(firestore, 'users'));
        const userData = usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setUsers(userData);
        setSelectedMember(null); // Reset selected member
      }
    } catch (error) {
      console.error('Error deleting member:', error);
    }
  };

  // Define columns for the table
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        id: 'qrID',
        accessor: 'qrID',
        Cell: ({ value }) => (value),
      },
      {
        Header: 'Ime i prezime',
        id: 'fullName',
        accessor: 'fullName',
      },
      {
        Header: 'Email',
        id: 'email',
        accessor: 'email',
      },
      {
        Header: 'Telefon',
        id: 'phone',
        accessor: 'phone',
      },
      {
        Header: 'Vrsta kartice',
        id: 'isSeasonCard',
        accessor: 'isSeasonCard',
        Cell: ({ value }) => (value ? 'Sezonska kartica' : 'Članska kartica'),
      },
      {
        Header: 'Akcije',
        id: 'actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <div className="flex">
            <button
              onClick={() => viewMemberDetails(row.original)}
              className="bg-blue-500 text-white px-3 py-1 rounded-md mr-2"
            >
              <FontAwesomeIcon icon={faEye} /> {/* Font Awesome eye icon */}
            </button>
            <button
              onClick={() => confirmDeleteMember(row.original)}
              className="bg-red-500 text-white px-3 py-1 rounded-md"
            >
              <FontAwesomeIcon icon={faTrashAlt} /> {/* Font Awesome trash icon */}
            </button>
          </div>
        ),
      },
    ],
    [viewMemberDetails, confirmDeleteMember] // Include viewMemberDetails and confirmDeleteMember in the dependency array
  );

  return (
    <div className="flex h-screen bg-gray-100">
      <Navbar />
      <div className="flex-1 p-8">
        <h1 className="text-2xl mb-4">Članovi</h1>
        <div className="flex items-center mb-4">
          <input
            type="text"
            placeholder="Pretraži po imenu ili ID-u"
            className="bg-white border border-gray-300 rounded-md px-4 py-2 w-96 mr-4"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="overflow-x-auto">
          {/* Conditionally render the Loader component if loading is true */}
          {loading ? (
            <Loader />
          ) : (
            <Table data={users} columns={columns} />
          )}
        </div>
      </div>
      {/* Delete member confirmation popup */}
      {selectedMember && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-2">Da li ste sigurni da želite obrisati ovog člana?</p>
            <div className="flex justify-end">
              <button onClick={() => setSelectedMember(null)} className="bg-gray-300 px-3 py-1 rounded-md mr-2">
                Ne
              </button>
              <button onClick={deleteMember} className="bg-red-500 text-white px-3 py-1 rounded-md">
                Da
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Members;
