import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import Loader from '../components/shared/Loader';

const QrResolver = () => {
  const { qrID } = useParams();
  const [isActive, setIsActive] = useState(null); // Initialize isActive as null to handle loading state

  useEffect(() => {
    const fetchQrStatus = async () => {
      try {
        const q = query(collection(firestore, 'qr_codes'), where('qrID', '==', parseInt(qrID))); // Filter by qrID
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const qrData = doc.data();
            const isActive = qrData.isActive;
            setIsActive(isActive);
          });
        } else {
          console.error('No documents found for qrID:', qrID);
          setIsActive(false); // Set isActive to false if no documents found
        }
      } catch (error) {
        console.error('Error fetching QR code status:', error);
        setIsActive(false); // Set isActive to false in case of error
      }
    };

    fetchQrStatus();
  }, [qrID]);

  if (isActive === null) {
    // Render your Loader component instead of the text
    return <Loader />;
  }

  // Redirect based on isActive status
  if (isActive) {
    return <Navigate to={`/qr/fanzone/${qrID}`} />;
  } else {
    return <Navigate to={`/qr/registration/${qrID}`} />;
  }
};

export default QrResolver;
