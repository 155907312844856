// withAuthProtection.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const withAuthProtection = (Component) => (props) => {
  // Check if the authentication cookie exists
  const authToken = document.cookie.replace(
    /(?:(?:^|.*;\s*)authToken\s*=\s*([^;]*).*$)|^.*$/,
    '$1'
  );

  // If authToken exists, render the protected component, else redirect to login
  return authToken ? <Component {...props} /> : <Navigate to="/login" />;
};

export default withAuthProtection;
